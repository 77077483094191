var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      style: { width: _vm.width, cursor: "pointer" },
      on: { click: _vm.clicked }
    },
    [
      _c(
        "card",
        {
          attrs: {
            "card-id": _vm.routeId,
            shadow: _vm.shadow,
            "body-style": { padding: "15px" }
          }
        },
        [
          _c("div", { staticClass: "user-card-body" }, [
            _c(
              "div",
              { staticStyle: { height: "56px" } },
              [_c("el-avatar", { attrs: { size: 56, src: _vm.getImage() } })],
              1
            ),
            _c("div", { staticClass: "user-card-info" }, [
              _c("span", { staticClass: "user-card-info-name truncate" }, [
                _vm._v(
                  _vm._s(_vm.data.first_name) + " " + _vm._s(_vm.data.last_name)
                )
              ]),
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm._f("driver_passenger_text")(_vm.data.carpooling_details)
                  )
                )
              ])
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }