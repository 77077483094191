<template>
  <div @click="clicked" :style="{ width: width, cursor: 'pointer' }">
    <card
      :card-id="routeId"
      :shadow="shadow"
      :body-style="{'padding': '15px'}"
    >
      <div class="user-card-body">
        <div style="height: 56px">
          <el-avatar :size="56" :src="getImage()"></el-avatar>
        </div>
        <div class="user-card-info">
          <span class="user-card-info-name truncate">{{ data.first_name }} {{ data.last_name }}</span>
          <span>{{ data.carpooling_details | driver_passenger_text }}</span>
        </div>
      </div>

      <!-- <span class="carpooling-description">{{ data.description.length > 150 ? data.description.substr(0, 150) + '...' : data.description }}</span> -->
    </card>
  </div>
</template>

<script>
import Card from '@/components/Card'
import utils from '@/utils/mixin_methods'


export default {
  components: { Card },
  props: {
    width: {
        type: String,
        default: '100%'
    },
    shadow: String,
    routeId: String,
    data: {
			type: Object,
			default: () => { 
				return {
					
				}
			}
		},

  },
  data() {
    return {

    }
  },

  mounted() {},

  methods: {
    clicked() {
      return this.$emit('clicked', this.routeId)
		},
		
		getImage() {
			return this.getUserPhoto(this.routeId, 128);
		}
  },

  filters: {
    driver_passenger_text(details) {
      if (details.driver && details.passenger) {
        return 'Driver & passenger';
      } else if (details.driver) {
        return 'Driver only';
      } else {
        return 'Passenger only';
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.carpooling-description {
    display: block;
    margin-top: 10px;
}
</style>