<template>
  <div v-loading.fullscreen.lock="loading.all" id="search_page">
      <div id="search_page_information">
        <el-card :body-style="{padding: '10px'}" shadow="never" style="border: 0">
            <div class="search-direction-container">
              <div class="search-direction-inputs">
                <el-form ref="form" :model="search_info" label-width="50px" size="mini">
                  <el-form-item label="From:">
                    <span class="search-direction-direction">{{ search_info.from }}</span>
                  </el-form-item>

                  <el-form-item style="margin-bottom: 0" label="To:">
                    <span class="search-direction-direction">{{ search_info.to }}</span>
                  </el-form-item>
                </el-form>
              </div>

              <div v-if="false" class="search-direction-switcher">
                <div @click="switchDirection" :class="{icon: true, turned: direction == 'back'}">
                  <font-awesome-icon style="transform: rotate(90deg);" :icon="['fas', 'exchange-alt']"></font-awesome-icon>
                </div>
              </div>
            </div>
        </el-card>

          <el-divider id="divider"></el-divider>

          <div class="container">
            <!-- Carpooling -->
            <div v-if="false">
              <span class="mini-title">Carpooling</span>

              <el-row v-loading="loading.carpooling" :gutter="20">
                <el-col :span="24" :key="dr.id" v-for="dr in carpooling_routes">
                  <carpooling-card
                  :data="dr.data"
                  :route-id="dr.id"
                  @clicked="onDrivingRouteClicked"></carpooling-card>
                </el-col>

                <el-col :span="24" v-if="carpooling_routes.length < 1">
                  <el-card>
                    <span style="text-align: center; display: block;">There's no people carpooling right now. We'll let you know when there's someone available in your area.</span>
                  </el-card>
                </el-col>
              </el-row>
            </div>

            <!-- Shuttle -->
            <div v-if="true">
              <span class="mini-title">Shuttles</span>

              <el-row v-loading="loading.shuttle" :gutter="20">
                <el-col :span="24">
                  <div @click="$router.push('/shuttle-route/sdf')" class="base-card shuttle-card">
                    <img :src="publicPath + 'img/activity-icons/bus_white.png'">
                    <div>
                      <h5>Greystones Shuttle</h5>
                      <p>Mon, Tue, Wed, Thu & Fri</p>
                    </div>
                  </div> 
                </el-col>

                <el-col :span="24">
                  <div @click="$router.push('/shuttle-route/sdf')" class="base-card shuttle-card">
                    <img :src="publicPath + 'img/activity-icons/bus_white.png'">
                    <div>
                      <h5>Blackrock Shuttle</h5>
                      <p>Mon, Tue, Wed, Thu & Fri</p>
                    </div>
                  </div> 
                </el-col>

                <el-col :span="24">
                  <div @click="$router.push('/shuttle-route/sdf')" class="base-card shuttle-card">
                    <img :src="publicPath + 'img/activity-icons/bus_white.png'">
                    <div>
                      <h5>City Center Shuttle</h5>
                      <p>Mon, Tue, Wed, Thu & Fri</p>
                    </div>
                  </div> 
                </el-col>

                <el-col :span="24">
                  <div @click="$router.push('/shuttle-route/sdf')" class="base-card shuttle-card">
                    <img :src="publicPath + 'img/activity-icons/bus_white.png'">
                    <div>
                      <h5>Dun Laoghaire Shuttle</h5>
                      <p>Mon, Tue, Wed, Thu & Fri</p>
                    </div>
                  </div> 
                </el-col>
              </el-row>
            </div>

            <!-- <span class="mini-title">Public transport</span>

            <el-row v-loading="loading.public_transport" :gutter="20">
              <el-col v-for="(route, key) in public_transport_routes[direction]" :key="key">
              <public-transit-card :route-leg="route.legs[0]" :route-polyline="route.overview_polyline"></public-transit-card>
              </el-col>

              <el-col :span="24" v-if="public_transport_routes[direction].length < 1">
                 <el-card>
                  <span style="text-align: center; display: block;">No public transport available right now.</span>
                </el-card>
              </el-col>
            </el-row> -->

            <!-- <span class="mini-title">Shuttle buses</span>

            <el-row v-loading="loading.shuttle" :gutter="20">
              <el-col>
                <el-card>
                  <span style="text-align: center; display: block;">No shuttle buses available right now.</span>
                </el-card>
              </el-col>
            </el-row> -->
          </div>

      </div>      
    </div>
</template>

<script>
import CarpoolingCard from '@/components/CarpoolingCard';
import PublicTransitCard from '@/components/PublicTransitCard';
import Modal from '@/components/Modal/Modal';
import FormItem from '@/components/Form/FormItem';
import initGoogleMaps from '@/utils/gmaps';
import lodash from 'lodash';

export default {
    components: { Modal, CarpoolingCard, PublicTransitCard, FormItem },

    data() {
      return {
        publicPath: process.env.BASE_URL,
        loading: {
          all: false,
          carpooling: true,
          shuttle: false,
          public_transport: true
        },
        direction: 'go',
        search_info: {
          from: 'Home',
          to: 'Office'
        },
        carpooling_routes: [],
        public_transport_routes: {
          go: [],
          back: []
        },
        shuttle_routes: []
      }
    },

    mounted() {
      this.onSearch();
    },

    computed: {
      user() {
        return this.$store.getters.user.data;
      }
    },

    methods: {
      async onSearch() {

        let public_transport = JSON.parse(localStorage.getItem('public_transport_routes'));

        // If we have cached the public transport results less than a month ago
        if (public_transport && public_transport.updated_at > (Date.now() - (1000 * 60 * 60 * 24 * 30))) {
          this.public_transport_routes = public_transport.routes;
        } else {
          public_transport = null;
        }
        
        // get option
        this.SharoAPI({ action: 'findMobilityOptions', data: {
          modules: { carpooling: true, shuttle: true, public_transport: false }
        } }).then(r => {
          console.log(r)
          if (r.data.error) {
            return this.$message({ type: 'error', showClose: true, message: r.data.error_message });
          }


          if (r.data.routes.carpooling) {
            this.carpooling_routes = r.data.routes.carpooling;
          }

          if (r.data.routes.shuttle) {
            this.shuttle_routes = r.data.routes.shuttle;
          }

          if (r.data.routes.public_transport) {
            localStorage.setItem('public_transport_routes', JSON.stringify({ routes: r.data.routes.public_transport, updated_at: Date.now() }));
            this.public_transport_routes = r.data.routes.public_transport;
          }
        }).finally(_ => {
          this.loading.carpooling = false;
          this.loading.shuttle = false;
          this.loading.public_transport = false;
        });

        
      },

      onDrivingRouteClicked(id) {
        this.$router.push(`/carpooling-profile/${id}`)
      },

      switchDirection() {
        this.direction == 'go' ? this.direction = 'back' : this.direction = 'go';
        let from = this.search_info.from;
        this.search_info.from = this.search_info.to;
        this.search_info.to = from;
        this.loading.all = true;

        setTimeout(_ => {
          this.loading.all = false;
        }, 750);
      }
    }

}
</script>


<style lang="scss" scoped>
  @import "@/theme/variables.scss";

  @media (min-width: 769px) {
    #search_page_information {
      max-width: 600px;
      margin: auto;
    }
  }
  
  .shuttle-card {
    display: flex;
    align-items: center;
    padding: 15px;

    img {
      width: 50px;
    }

    div {
      margin-left: 10px;
    }

    p.soon-arriving {
      color: #f56c6c;
      font-weight: 500;
    }
  }
  
  
  
  #search_page_col_information {
    border: 1px solid blue;
  }

  #search_page_col_map {
    border: 1px solid green;
  }

  #divider {
    margin-top: 0px !important;
    margin-bottom: 0;
  }

  .el-col {
    margin-bottom: 10px;
  }

  .search-direction-direction {
    display: block;
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 3px;
    padding-left: 7px;
  }

  .search-direction-container {
    margin: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .search-direction-inputs {
      flex: 1;
      min-height: 80px;
      padding: 7px;

      .el-form-item {
        margin-bottom: 10px;
      }
    }

    .search-direction-switcher {
      width: 50px;
      height: auto;
      padding: 5px;
      text-align: center;

      .icon {
        padding-top: 1px;
        border-radius: 5px;
        color: rgb(100, 100, 100);
        cursor: pointer;
        font-size: 20px;
        transform: rotate(180deg);
        transition: transform 150ms ease;

        &.turned {
          transform: rotate(360deg);
          transition: transform 150ms ease;
        }
      }
    }
  }
</style>