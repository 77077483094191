var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("card", { staticClass: "public-transit-card" }, [
    _c("div", { staticClass: "public-transit-time" }, [
      _c("span", { staticClass: "times" }, [
        _vm._v(
          _vm._s(_vm.routeLeg.departure_time.text) +
            " - " +
            _vm._s(_vm.routeLeg.arrival_time.text)
        )
      ]),
      _c("span", { staticClass: "duration" }, [
        _vm._v(_vm._s(_vm.routeLeg.duration.text))
      ])
    ]),
    _c(
      "div",
      { staticClass: "public-transit-breakdown" },
      _vm._l(_vm.steps, function(step, key) {
        return _c("div", { key: key, staticClass: "public-transit-step" }, [
          _c("img", {
            staticClass: "step-icon",
            attrs: {
              src:
                step.transit_details.line.vehicle.local_icon ||
                step.transit_details.line.vehicle.icon
            }
          }),
          _c(
            "span",
            {
              staticClass: "step-short-name",
              style: {
                color: step.transit_details.line.text_color,
                "background-color": step.transit_details.line.color
              }
            },
            [
              _vm._v(
                _vm._s(
                  step.transit_details.line.short_name ||
                    step.transit_details.line.name
                )
              )
            ]
          ),
          _c("span", { staticClass: "step-arrow" }, [
            _vm._v("\n\t\t\t\t\t\t-\n\t\t\t\t\t")
          ])
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }