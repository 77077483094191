<template>
		<card class="public-transit-card">
      <div class="public-transit-time">
				<span class="times">{{ routeLeg.departure_time.text }} - {{ routeLeg.arrival_time.text }}</span>
				<span class="duration">{{ routeLeg.duration.text }}</span>
			</div>

			<div class="public-transit-breakdown">
				<div v-for="(step, key) in steps" :key="key" class="public-transit-step">
						<img class="step-icon" :src="step.transit_details.line.vehicle.local_icon || step.transit_details.line.vehicle.icon">
					<span class="step-short-name" :style="{ color: step.transit_details.line.text_color, 'background-color': step.transit_details.line.color }">{{ step.transit_details.line.short_name || step.transit_details.line.name }}</span>
					<span class="step-arrow">
						-
					</span>
				</div>
			</div>

			<!-- <div class="public-transit-step-by-step">
				<div v-if="show_details" class="public-transit-steps"></div>
			</div>

			<div class="public-transit-show-hide">
				<span @click="toggleDetails()">{{ show_details ? 'Hide details' : 'Show details' }}</span>
			</div> -->
		</card>
</template>

<script>
import Card from '@/components/Card'

export default {
	components: { Card },
	props: {
		routeLeg: {
			type: Object
		},
		routePolyline: {
			type: Object
		}
	},
	data() {
		return {
			show_details: false
		}
	},

	methods: {
		toggleDetails() {
			this.show_details = !this.show_details;
		}
	},

	computed: {
		steps() {
			return this.routeLeg.steps.filter(step => 'transit_details' in step);
		}
	}
}
</script>

<style lang="scss" scoped>
	@import '@/theme/variables.scss';

	.public-transit-card:active div.el-card {
		border-color: $secondary-color;
	}

	.public-transit-card {

		.public-transit-time {
			display: flex;
			align-items: center;
			justify-content: space-between;

			span {
				display: block;
				font-size: 16px;

				&.times {
					
				}

				&.duration {
					font-weight: 500;
				}
			}
		}

		.public-transit-breakdown {
			margin-top: 5px;
			margin-bottom: -4px;
			width: 100%;
			// border: 1px solid blue;
			padding: 2px 2px 0px 2px;

			display: flex;
			align-items: center;
			flex-wrap: wrap;

			.public-transit-step {
				margin-bottom: 4px;
				margin-top: 2px;
				display: flex;
				align-items: center;

				& > * {
					margin-right: 3px;
				}

				.step-short-name {
					background-color: rgb(240, 240, 240);
					color: $text-color;
					padding: 2px 6px;
					border-radius: 2px;
				}

				.step-icon {
					max-height: 20px;
				}

				.step-arrow {
					margin: 0 6px;
				}
			}

			.public-transit-step:last-child .step-arrow {
				display: none;
			}
		}

		.public-transit-show-hide {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			margin-top: 5px;

			span {
				display: block;
				color: $blue-color;
				cursor: pointer;
			}
		}

		.public-transit-step-by-step {
			margin-top: 5px;
		}

		.public-transit-steps {
			border: 1px solid blue;
			min-height: 350px;
		}

	}
</style>